import classNames from "classnames";
import Footer from "components/footer/footer";
import Menu from "components/menu/menu";
import React, { useState, useEffect } from "react";
import Header from "../components/header/header";
import { useLocation } from "@reach/router";

//
import "./layout.scss";
import NavOverlay from "components/navOverlay/navOverlay";
import { useOptions } from "hooks/useOptions.query";
import Popup from "components/popup/popup";
import Cookies from "js-cookie";
import CookieNotice from "components/cookieNotice/cookieNotice";
import { matchContact } from "data/location.data";

interface Props {
  pageContext: {
    slug: string;
    id: string;
    singlePost: boolean;
  };
  children?: JSX.Element | JSX.Element[];
}

const Layout: React.FC<Props> = (props) => {
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [frontPage, setFrontPage] = useState<boolean>(false);
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(true);
  const [cookieNoticeOpen, setCookieNoticeOpen] = useState<boolean>(false);
  const [isContact, setIsContact] = useState<boolean>(false);
  const location = useLocation();
  const { wp } = useOptions();
  const { popup } = wp.themeGeneralSettings;

  useEffect(() => {
    setFrontPage(location.pathname === "/" || location.pathname === "/en/");
    setIsContact(matchContact.includes(location.pathname));
    // Menu closing on new page
    setMenuOpen(false);
  }, [location]);
  console.log(isContact);

  useEffect(() => {
    if (!Cookies.get("cookies-accepted")) {
      setCookieNoticeOpen(true);
    }
  }, []);

  const onPopupClose = () => {
    setIsPopupOpen(false);
    Cookies.set("popup-closed", true);
  };

  const onCookieNoticeClose = () => {
    setCookieNoticeOpen(false);
    Cookies.set("cookies-accepted", true);
  };

  const isPopupCookieSet = Cookies.get("popup-closed");

  return (
    <div
      className={classNames("layout", {
        "is-menu-open": menuOpen || frontPage,
        "is-frontpage": frontPage,
      })}
    >
      <NavOverlay isOpen={menuOpen || frontPage} isFrontPage={frontPage} />

      <Header
        isOpen={menuOpen}
        onMenuClick={setMenuOpen}
        isFrontPage={frontPage}
      />

      {props.children}

      {isContact && (
        <div className="compliance-logo">
          <a
            href="https://heydata.eu"
            target="_blank"
            title="heyData GDPR seal"
          >
            <img
              alt="heyData trusted logo"
              src="https://privacy-seal.heydata.eu/seal/d0986d19-12fa-40a4-872e-86279d297068?lang=de&design=design2&sealType=GDPR"
              style={{ border: 0 }}
              width="600"
              height="600"
            />
          </a>
        </div>
      )}

      <Footer pageContext={props.pageContext} isFrontPage={frontPage} />
      {popup.active &&
        isPopupOpen &&
        !isPopupCookieSet &&
        !cookieNoticeOpen &&
        location.pathname === "/" && (
          <Popup {...popup} onClose={onPopupClose} />
        )}
      {cookieNoticeOpen && <CookieNotice onClose={onCookieNoticeClose} />}
    </div>
  );
};

export default Layout;
